import { createRouter, createWebHashHistory } from 'vue-router';
import { alert } from '@/utils/weui';
import NProgress from 'nprogress'; // 引入进度条插件
import 'nprogress/nprogress.css'; // 引入对应css样式

import routes from './routes';

NProgress.configure({
  showSpinner: false,
});

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return false;
  },
});

router.beforeEach(async (to, from, next) => {
  console.log('beforeEach');
  NProgress.start();
  next();
});

router.afterEach((to, from) => {
  console.log('afterEach');
  if (to.meta.title) {
    document.title = to.meta.title as string;
  }
  NProgress.done();
});

router.onError((error) => {
  console.log('路由报错', error.message);
  // Error: Loading chunk preStowage failed. (/css/preStowage.c5f746b4.css) at HTMLLinkElement.linkTag.onerror
  // Error: Loading css chunk preStowage failed. (/css/preStowage.c5f746b4.css) at HTMLLinkElement.linkTag.onerror
  // ERROR: Unable to preload CSS for /css/claims.00441be4.1226_1045.css
  const pattern = /Loading(.*?)chunk(.*?)failed/g;
  const pattern2 = /Unable to preload CSS for/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const isChunkLoadFailed2 = error.message.match(pattern2);
  console.log('isChunkLoadFailed', isChunkLoadFailed);
  console.log('isChunkLoadFailed2', isChunkLoadFailed2);
  if (isChunkLoadFailed || isChunkLoadFailed2) {
    // 当前页面有更新，请刷新页面重新进入
    alert('页面有更新，请重新进入或刷新页面');
  } else if (error.message.indexOf('Cannot find module') >= 0) {
    // 没有找到页面，请刷新页面重试
    alert('没有找到页面，请刷新页面重试');
  }
});

export default router;